.toggle-button {
  display: flex;
  z-index: 2;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  box-sizing: border-box;
  flex-direction: column;
  height: 24px;
  width: 30px;
}

.toggle-button:focus {
  outline: none;
}

.icon {
  color: #fff;
  font-size: 26px;
}

@media (min-width: 899px) {
  .toggle-button {
    display: none;
  }
}
