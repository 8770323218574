.side-drawer {
  height: 100% !important;
  background: #001529;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  max-width: 400px;
  z-index: 200 !important;
  transform: translateX(200%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0%);
}

.links-drawer {
  display: flex;
  flex-direction: column;
}

@media (min-width: 899px) {
  .side-drawer {
    display: none;
  }
}
