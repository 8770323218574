.title {
  text-transform: uppercase;
}

.content {
  height: 100%;
  padding: 24px 64px;
  background: #2a2e37;
  text-align: center;
}

#inicio {
  background-color: #2a2e37;
  text-align: justify;
  background: url(https://imgur.com/uKfIFxh.png);
  background-size: cover;
}

#sobre {
  background-color: #2a2e37;
  color: #ffd870;
  letter-spacing: -0.1px;
  font-size: 18px;
  text-align: justify;
}

#contato {
  background-color: #ffd870;
}

.logo {
  padding: 0px;
  padding-top: 10px;
  margin: 0px;
}

.linkAlexandre {
  color: #2a2e37 !important;
  padding: 0 !important;
  margin: 0 !important;
}
.linkAlexandre:hover {
  color: #1890ff !important;
  background-color: #f0f2f5;
}

.socials-link-face {
  color: #2a2e37 !important;
}

.socials-link-face:hover {
  color: #1890ff !important;
  background-color: #f0f2f5;
}

.socials-link-insta:hover {
  color: #517fa4 !important;
  background-color: #f0f2f5;
}

.socials-link-insta {
  color: #2a2e37 !important;
}

h1 {
  font-size: 25px;
}

h3 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

.active {
  color: #fff;
  background-color: #1890ff;
  padding: 15px;
  border-radius: 3px;
}

a:hover {
  padding: 15px;
  background-color: #1890ff;
  border-radius: 3px;
}

a {
  padding: 15px;
  margin: 10px;
  color: #fff !important;
}

.spacer {
  flex: 1;
}

.header {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  line-height: 32px !important;
}

@media (max-width: 899px) {
  .navigation__items {
    display: none;
  }
  .logo {
    display: none;
  }
  .logoMobile {
    display: flex;
  }

  .content {
    padding-top: 0;
  }
}

@media (min-width: 900px) {
  .logoMobile {
    display: none;
  }

  .content {
    padding-top: 150px;
  }
}

@media (max-width: 530px) {
  .imgDesktop {
    display: none;
  }

  .imgMobile {
    display: flex;
  }
}

@media (min-width: 531px) {
  .imgDesktop {
    display: flex;
  }

  .imgMobile {
    display: none;
  }
}
